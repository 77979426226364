const Noodl = require('@noodl/noodl-sdk');
import { QueryClient, QueryClientProvider, useQuery, } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import React, { useState } from 'react';
import { DataTable } from 'primereact/datatable'; import { Column } from 'primereact/column'; import { Message } from 'primereact/message';
import { Image } from 'primereact/image'; import { Button } from 'primereact/button'; import { InputText } from 'primereact/inputtext';
import "primereact/resources/primereact.min.css";
import "../primereact-sass-theme/themes/rolder/theme.scss";
import "/node_modules/primeflex/primeflex.css"
import "primeicons/primeicons.css";
import "./index.css";

const queryClient = new QueryClient();

const Table = (props) => {
    const debug = props.debug
    const className = props.className
    // init ts
    const tsEnv = props.env.typeSense
    const typeSense = new Typesense.Client({
        nodes: [{
            host: tsEnv.host,
            port: 443,
            protocol: 'https'
        }],
        apiKey: tsEnv.key,
        connectionTimeoutSeconds: tsEnv.timeout
    })

    // reset 
    if (props.onResetQueries === 'reset') {
        queryClient.invalidateQueries()
        props.onResetQueries = ''
    }

    // get initial totalRecords    
    const parseCountQuery = new Parse.Query(Parse.Object.extend(className));
    const countQuery = async () => {
        parseCountQuery.withCount(true)
        parseCountQuery.limit(0)
        if (debug > 0) console.time(className + '_countTime')
        const response = await parseCountQuery.find()
        if (debug > 0) console.timeEnd(className + '_countTime')
        if (debug > 1) console.log(className + '_count', response.count)
        return response.count;
    }

    const { isFetched, data: totalRecords } = useQuery({
        queryKey: [
            'ParseCount' + className,
            parseCountQuery,
        ],
        queryFn: countQuery,
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        staleTime: 360000
    })
    const [loading, setLoading] = useState(!isFetched)

    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: props.pageSize,
    });
    const onPage = (event) => { setlazyState(event) };

    // parse
    const parseQuery = new Parse.Query(Parse.Object.extend(className));
    parseQuery.skip(lazyState.first)
    parseQuery.limit(lazyState.rows)
    const baseQuery = async () => {
        setLoading(true)
        parseQuery.withCount(false)
        if (debug > 0) console.time(className + '_parseFetch')
        const response = await parseQuery.find()
        if (debug > 0) console.timeEnd(className + '_parseFetch')
        let items = []
        response.forEach(r => {
            items.push(Noodl.Object.create(Object.assign({ id: r.id }, r.attributes)))
        })
        if (debug > 1) console.log(className + '_parseItems', items)
        setLoading(false)
        return { items: items };
    }

    // ts
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const tsQuery = {
        q: globalFilterValue,
        query_by: 'name',
        page: lazyState.first / lazyState.rows + 1,
        per_page: lazyState.rows
    }

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        setGlobalFilterValue(value);
        setlazyState({ first: 0, rows: props.pageSize });
    };
    const onResetSearch = () => { setGlobalFilterValue('') };

    const searchQuery = async () => {
        setLoading(true)
        if (debug > 0) console.time(className + '_tsFetch')
        const response = await typeSense.collections(className).documents().search(tsQuery)
        if (debug > 0) console.timeEnd(className + '_tsFetch')
        let items = []
        response.hits.forEach(r => {
            items.push(Noodl.Object.create(Object.assign(r.document)))
        })
        if (debug > 1) console.log(className + '_tsItems', items)
        setLoading(false)
        return { items: items, found: response.found };
    }

    // query request
    const { data } = useQuery({
        queryKey: [
            globalFilterValue ? 'Ts' + className : 'Parse' + className,
            globalFilterValue ? tsQuery : parseQuery,
        ],
        queryFn: globalFilterValue ? searchQuery : baseQuery,
        keepPreviousData: true,
        refetchOnWindowFocus: true,
        staleTime: 360000,
        enabled: !!totalRecords,
    })

    // columns
    const imageBodyTemplate = (item) => {
        if (item.imageSmall) return (
            <div className="card flex justify-content-center">
                <Image src={item.imageSmall} zoomSrc={item.imageLarge} alt="Image" preview className="p-1 shadow-2 border-round inTableImg" />
            </div>
        )
    };
    const columns = props.columns.map((col) => {
        switch (col.type) {
            case 'image_v1': return <Column header={col.header} body={imageBodyTemplate} className="flex justify-content-center"></Column>
            default: return <Column key={col.field} field={col.field} header={col.header} />
        }
    })

    // header
    let header = null
    switch (props.headerTemplate) {
        case 'addButton': header =
            <div className="flex justify-content-between gap-2">
                <div className="flex align-items-center">
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon"><i className="pi pi-search" /></span>
                        <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="поиск" />
                        <Button icon="pi pi-times" className="p-button-secondary" onClick={onResetSearch} />
                    </div>
                    <span className="ml-2 font-normal">{data?.found}</span>
                </div>
                <Button icon="pi pi-plus" rounded raised onClick={props.onAddButton} />
            </div>
    }

    // footer
    const footer = <p>Всего: {totalRecords}</p>;

    const EmptyMessage = () => {
        return <Message severity="info" text="Нет данных по вашему запросу" />;
    };

    return (
        <React.StrictMode>
            <DataTable
                // data
                value={data?.items}
                dataKey="id"
                lazy
                // pagination
                paginator
                alwaysShowPaginator={false}
                paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
                rowsPerPageOptions={[10, 20, 30, 50, 100]}
                currentPageReportTemplate="Страница {currentPage} из {totalPages}"
                totalRecords={data?.found ? data.found : totalRecords}
                first={lazyState.first}
                rows={lazyState.rows}
                onPage={onPage}
                loading={loading}
                // filters, sort
                globalFilterFields={['name']}
                //sortMode="multiple"
                //removableSort
                // style
                header={header}
                footer={footer}
                className="shadow-2"
                tableStyle={{ minWidth: '40rem' }}
                emptyMessage={EmptyMessage}
                responsiveLayout="scroll"
                size='small'
                stripedRows
                scrollable
                scrollHeight="flex"
            >
                {columns}
            </DataTable>
        </React.StrictMode>
    )
};

const TableWithReactQueryProvider = (props) => {
    // get debug param
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    if (urlParams.get('debug')) { props.debug = parseInt(urlParams.get('debug')) }

    const DevTools = () => {
        if (props.debug > 1) return <ReactQueryDevtools initialIsOpen={false} />
        else return <></>
    }

    return (
        <QueryClientProvider client={queryClient}>
            <Table {...props} />
            <DevTools />            
        </QueryClientProvider>
    )
};

const TableNode = Noodl.defineReactNode({
    name: 'Table v1.0.1',
    getReactComponent() {
        return TableWithReactQueryProvider;
    },
    inputProps: {
        env: { type: 'object', group: "Params", displayName: "Environment" },
        className: {
            type: {
                name: "enum",
                enums: ['Section', 'Product', 'FeatureType'],
            },
            group: "Params",
            displayName: "Class",
            default: 'Product',
        },
        columns: { type: 'array', group: "Params", displayName: "Columns" },
        pageSize: { type: 'number', group: "Params", displayName: "Rows per page", default: 10 },
        headerTemplate: {
            type: {
                name: "enum",
                enums: [
                    { value: 'addButton', label: 'Add button' }
                ],
            },
            group: "Params",
            displayName: "Header template",
            default: null,
        },
        onResetQueries: {type: 'string', group: 'Signals', displayName: 'Reset all queries'}
    },
    outputProps: {
        onAddButton: { type: 'signal', displayName: 'Add button clicked' },
    },
})


Noodl.defineModule({
    reactNodes: [TableNode],
    nodes: [],
    setup() { }
});